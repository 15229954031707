<template>
  <div>
    <v-dialog max-width="450" v-model="dialog">
      <v-card>
        <v-card-title class="text-h6 lighten-2">
          Delete recording
          <v-spacer></v-spacer>
          <v-icon @click="closeDialog" size="18" class="white--black">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-6 text-left"
          >Would you like to permanently delete this recording <b>{{ title }}</b
          >? Once deleted, this recording will no longer be
          accessible.</v-card-text
        >
        <v-card-actions class="pb-10 pt-5">
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDialog"
            class="grey--text capitalize px-10"
            rounded
            text
          >
            Close
          </v-btn>
          <v-btn
            @click="handleDelete(item)"
            class="capitalize px-10"
            color="error"
            rounded
            :loading="loading"
          >
            Permanent Delete
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ResponseHelper from "../../utils/ResponseHelper";
import { mapActions, mapMutations } from "vuex";
export default {
  name: "DeleteRecordingDialog",
  props: {
    type: String,
    default: null,
  },
  data() {
    return {
      item: {},
      dialog: false,
      title: "",
      loading: false,
      toast: new ResponseHelper(),
    };
  },
  methods: {
    ...mapActions("recordings", ["delete_recording"]),
    ...mapMutations("recordings", ["SHOW_DETAILS"]),
    openDialog(item) {
      this.title = item.title;
      this.item = Object.assign({}, item);
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    async handleDelete() {
      this.loading = true;
      try {
        const response = await this.delete_recording(this.item.id);
        this.$store.dispatch("recordings/dashboard_my_recordings");
        this.$store.dispatch("recordings/dashboard_archived_recordings");
        this.$store.dispatch("folders/my_folders");
        this.$store.dispatch("recordings/dashboard_received_recordings");
        this.$store.dispatch(
          "recordings/dashboard_received_archived_recordings"
        );
        this.loading = false;
        if (this.type) {
          this.SHOW_DETAILS(response.data);
        }
        this.closeDialog();
        this.toast.sendSuccess(response);
        if (!this.type) {
          this.$parent.nextPage();
        }
      } catch (e) {
        this.toast.sendError(e);
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
