<template>
  <div style="margin-top: 7vh">
    <v-row>
      <v-col cols="12" md="6" offset-md="3">
        <v-card class="pa-10 rounded-xl" flat>
          <div class="center-logo">
            <app-logo></app-logo>
          </div>
          <div class="d-flex justify-center">
            <strong>We are retrieving your file... please wait.</strong>
          </div>
          <div class="mt-5">
            <v-progress-linear
              height="15"
              color="primary"
              class="rounded-xl"
              v-model="progress"
            ></v-progress-linear>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppLogo from "./Logo";

export default {
  components: {
    AppLogo,
  },
  props: ["recordDetails"],
  name: "DownloadProgress",
  data() {
    return {
      completed: false,
      isPaused: false,
      progress: 0,
    };
  },
  watch: {
    status(val) {
      if (val === "success") {
        this.completed = true;
      }
    },
  },
  computed: {
    isUploading() {
      return this.status !== "success" && this.status !== "canceled";
    },
  },
  methods: {
    async processDownload() {
      try {
        const response = await window.axios.get(
          "/recordings/download/" + this.recordDetails.id,
          {
            responseType: "blob",
            onDownloadProgress: (progressEvent) => {
              this.progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            },
          }
        );

        this.$parent.downloading = false;

        let fileName =
          this.recordDetails.title ?? Math.floor(Math.random() * 1203423);
        if (fileName.length > 50) {
          fileName = fileName.substr(0, 50);
        }
        const url = window.URL.createObjectURL(
          new Blob([response], { type: "video/mp4" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (e) {
        this.$parent.downloading = false;
        this.$response.sendError(e);
      }
    },
  },
};
</script>

<style scoped></style>
