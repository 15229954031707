<template>
  <div class="folder-item-container">
    <label class="k-folder-label-box">
      <input
        type="checkbox"
        :value="folder.id"
        :checked="isSelected"
        :ref="'checkedFolder_' + folder.id"
        @change="selectFolder(folder.id)"
        class="folder-selector"
      />
      <div class="folder-selector-box">
        <v-icon
          v-if="hasChildren(folder)"
          class="k-children-btn"
          @click="toggleExpand(folder)"
        >
          {{ isExpanded(folder) ? "mdi-menu-up" : "mdi-menu-down" }}
        </v-icon>
        <v-icon size="20" class="black--text folder-icon">
          {{ isExpanded(folder) ? "mdi-folder-open" : "mdi-folder-outline" }}
        </v-icon>
        {{ folder.name }}
      </div>
    </label>
    <div class="folder-container" v-if="isExpanded(folder)">
      <folder-item
        v-for="child in folder.children"
        :key="child.id"
        :folder="child"
        :selected_folder_id="selected_folder_id"
        :level="level + 1"
        @folder-selected="updateSelectedFolder"
      ></folder-item>
    </div>
  </div>
</template>

<script>
export default {
  name: "FolderItem",
  props: {
    folder: Object,
    selected_folder_id: String,
    level: Number,
  },
  components: {
    FolderItem: () => import("./FolderItem.vue"),
  },
  data() {
    return {
      expanded_folders: [],
    };
  },
  computed: {
    isSelected() {
      return this.folder.id === this.selected_folder_id;
    },
  },
  methods: {
    getPaddingLeft() {
      const topLevelPadding = 50;
      const childPadding = 20;
      return this.level === 1
        ? (this.level - 1) * topLevelPadding
        : this.level * childPadding;
    },
    selectFolder(Id) {
      const isChecked = this.$refs[`checkedFolder_${Id}`].checked;
      if (isChecked) {
        this.$emit("folder-selected", this.folder.id);
      } else {
        this.$emit("folder-selected", null);
      }
    },
    updateSelectedFolder(selectedFolderId) {
      this.$emit("folder-selected", selectedFolderId);
      const isChecked = this.$refs[`checkedFolder_${selectedFolderId}`].checked;
      if (isChecked) {
        this.$emit("folder-selected", selectedFolderId);
      } else {
        this.$emit("folder-selected", null);
      }
    },
    hasChildren(folder) {
      return folder.children && folder.children.length > 0;
    },
    isExpanded(folder) {
      return this.expanded_folders.includes(folder.id);
    },
    toggleExpand(folder) {
      const index = this.expanded_folders.indexOf(folder.id);
      if (index !== -1) {
        // Folder is already expanded, collapse it
        this.expanded_folders.splice(index, 1);
      } else {
        // Expand folder
        this.expanded_folders.push(folder.id);
      }
    },
  },
};
</script>

<style scoped>
/** Folder Movement Styles */
.folder-container {
  padding-left: 20px;
}
.folder-selector {
  display: none;
}
.folder-selector-box {
  width: 100%;
  margin: 0px;
  padding: 10px 35px;
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid #fff !important;
}
.folder-selector-box:hover {
  background-color: #eee;
}
.folder-icon {
  color: #000;
}
/* Parent Folder */
.folder-selector:checked + .folder-selector-box {
  border: 2px solid blue !important;
  background-color: blue !important;
  color: #fff !important;
}
.folder-selector:checked + .folder-selector-box .folder-icon {
  color: #fff !important;
  transition: 0s;
}
.folder-selector:checked + .folder-selector-box .k-children-btn {
  color: #fff !important;
  transition: 0s;
}
/* Child Folder */
.k-children-btn {
  border: 1px solid #eee;
  border-radius: 50%;
  margin-left: -29px;
  margin-right: 4px;
}
.folder-selector:checked + .folder-selector-box .k-children-btn:hover {
  background-color: #888;
  border: 1px solid #fff !important;
}
.k-children-btn:hover {
  background-color: #888;
  border: 1px solid #fff !important;
}
</style>
