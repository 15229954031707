<template>
  <div>
    <v-dialog max-width="550" v-model="dialog" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 lighten-2">
          Move within My Recordings
          <v-spacer></v-spacer>
          <v-icon @click="closeDialog" size="18" class="white--black">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-left black--text pt-6" style="height: 350px">
          Move to:
          <div class="text-left">
            <!-- FolderItem Component -->
            <folder-item
              v-for="folder in list_of_folders"
              :key="folder.id"
              :folder="folder"
              :selected_folder_id="selected_folder_id"
              :level="0"
              @folder-selected="updateSelectedFolder"
            ></folder-item>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pb-10 pt-5">
          <v-row>
            <v-col cols="5" sm="5" class="text-left"></v-col>
            <v-col cols="7" sm="7" class="text-right">
              <v-btn
                @click="closeDialog"
                class="black--text capitalize px-4 mr-3"
                rounded
                outlined
                text
              >
                Cancel
              </v-btn>
              <v-btn
                @click="handleMoveRecording(recording_item)"
                class="capitalize px-4"
                color="primary"
                rounded
                :disabled="isMoveButtonDisabled"
                :loading="loading"
              >
                Move
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FolderItem from "../Folders/FolderItem.vue";
import ResponseHelper from "../../utils/ResponseHelper";
export default {
  name: "MoveRecordingToFolderDialog",
  components: {
    FolderItem,
  },
  props: {
    type: String,
    default: null,
  },
  data() {
    return {
      dialog: false,
      toast: new ResponseHelper(),
      loading: false,
      recording_item: {},
      list_of_folders: [],
      selected_folder_id: null,
      expanded_folders: [],
    };
  },
  computed: {
    isMoveButtonDisabled() {
      return !this.selected_folder_id;
    },
  },
  methods: {
    ...mapActions("folders", ["move_folders_list", "move_recording_to_folder"]),

    async moveFoldersList() {
      try {
        const response = await this.move_folders_list(null);
        this.list_of_folders = response.data;
      } catch (e) {
        this.$parent.toast.sendError(e);
      }
    },
    async handleMoveRecording(record) {
      this.loading = true;
      const form = {
        recording_id: record.id,
        folder_id: this.selected_folder_id,
      };

      try {
        const response = await this.move_recording_to_folder(form);
        this.$store.dispatch("folders/my_folders");
        this.$store.dispatch("recordings/dashboard_my_recordings");
        this.$store.dispatch("recordings/dashboard_received_recordings");
        this.$store.dispatch(
          "recordings/dashboard_received_archived_recordings"
        );
        this.$emit("load-recordings");
        this.loading = false;
        this.toast.sendSuccess(response);
        this.closeDialog();
        if (this.$route.name == "FoldersView") {
          window.location.reload();
        }
      } catch (e) {
        this.loading = false;
        this.toast.sendError(e);
      }
    },
    updateSelectedFolder(folderId) {
      this.selected_folder_id = folderId;
    },
    // createOnModalFolder() {},
    openDialog(record) {
      this.recording_item = Object.assign({}, record);
      // const Id = folder.id;
      this.moveFoldersList();
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.list_of_folders = [];
      this.selected_folder_id = null;
    },
  },
};
</script>

<style scoped>
.k-base-folder {
  padding: 15px 0px;
}
.k-base-folder > .k-base-folder-title {
  margin-left: 30px;
  margin-top: 8px;
  color: grey;
}
.k-create-folder-1 {
  padding: 4px 35px;
  margin-bottom: 4px;
  height: 40px;
  line-height: 30px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #eee !important;
}
.k-create-folder-1:hover {
  background-color: #eee;
}
/** Folder Movement Styles */
.folder-container {
  margin-bottom: 4px;
}
.folder-selector {
  display: none;
}
.folder-selector-box {
  width: 100%;
  margin: 0px;
  padding: 10px 35px;
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid #fff !important;
}
.folder-selector-box:hover {
  background-color: #eee;
}
.child-folder-selector-box {
  width: 100%;
  margin: 0px;
  padding: 10px 60px;
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid #fff !important;
}
.child-folder-selector-box:hover {
  background-color: #eee;
}
.folder-icon {
  color: #000;
}
/* Parent Folder */
.folder-selector:checked + .folder-selector-box {
  border: 2px solid blue !important;
  background-color: blue !important;
  color: #fff !important;
}
.folder-selector:checked + .folder-selector-box .folder-icon {
  color: #fff !important;
  transition: 0s;
}
/* Child Folder */
.folder-selector:checked + .child-folder-selector-box {
  border: 2px solid blue !important;
  background-color: blue !important;
  color: #fff !important;
}
.folder-selector:checked + .child-folder-selector-box .folder-icon {
  color: #fff !important;
  transition: 0s;
}
</style>
