<template>
  <div>
    <v-row justify="start" class="mb-5">
      <v-btn
        v-show="selected_ids.length >= 2"
        depressed
        class="rounded-xl capitalize"
        color="error"
        @click="deleteSelected"
      >
        Delete Selected
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "DeleteSelected",
  props: {
    selected_ids: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("recordings", ["delete_selected"]),
    async deleteSelected() {
      if (confirm("Are you sure you want to delete selected records")) {
        const data = {
          ids: this.selected_ids,
        };
        try {
          const response = await this.delete_selected(data);
          this.$parent.selected_ids = [];
          this.$parent.loading = false;
          this.$parent.nextPage();
          this.$parent.toast.sendSuccess(response);
        } catch (e) {
          this.$parent.loading = false;
          this.$parent.toast.sendError(e);
        }
      }
    },
  },
};
</script>

<style scoped></style>
