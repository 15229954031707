<template>
  <div>
    <v-dialog max-width="600" v-model="dialog">
      <v-card>
        <v-toolbar class="primary white--text" flat>
          <span class="font-weight-bold text-left">
            Edit - {{ title.length > 30 ? title.substr(0, 30) + "..." : title }}
          </span>
          <v-spacer></v-spacer>
          <v-icon @click="closeDialog" size="18" class="white--text">
            mdi-close
          </v-icon>
        </v-toolbar>
        <v-form ref="form" class="px-4 py-4">
          <v-text-field
            v-if="(this.type && this.type === 'title') || !this.type"
            v-model="item.title"
            background-color="secondary"
            solo
            flat
            dense
            rounded
          ></v-text-field>
          <v-textarea
            v-if="(this.type && this.type === 'note') || !this.type"
            v-model="item.message"
            background-color="secondary"
            solo
            flat
            dense
            rounded
          ></v-textarea>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="closeDialog"
              class="grey--text capitalize px-10"
              rounded
              text
            >
              Close
            </v-btn>
            <v-btn
              @click="updateRecording"
              class="primary capitalize px-10"
              rounded
              :loading="loading"
            >
              Update
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ResponseHelper from "../../utils/ResponseHelper";
import { mapActions, mapMutations } from "vuex";
export default {
  name: "EditRecordingDialog",
  props: {
    type: String,
    default: null,
  },
  data() {
    return {
      item: {},
      dialog: false,
      title: "",
      loading: false,
      toast: new ResponseHelper(),
    };
  },
  methods: {
    ...mapActions("recordings", ["update_recording"]),
    ...mapMutations("recordings", ["SHOW_DETAILS", "DASHBOARD_MY_RECORDINGS"]),
    openDialog(item) {
      this.title = item.title;
      this.item = Object.assign({}, item);
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    async updateRecording() {
      this.loading = true;
      try {
        const response = await this.update_recording(this.item);
        this.$store.dispatch("recordings/dashboard_my_recordings");
        this.$store.dispatch("folders/my_folders");
        if (this.$route.name == "FoldersView") {
          window.location.reload();
        }
        this.loading = false;
        if (this.type) {
          this.SHOW_DETAILS(response.data);
        }
        this.closeDialog();
        this.toast.sendSuccess(response);
        if (!this.type) {
          this.$parent.nextPage();
        }
      } catch (e) {
        this.toast.sendError(e);
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
