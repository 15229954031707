<template>
  <div>
    <my-recordings></my-recordings>
  </div>
</template>

<script>
import MyRecordings from "../../components/MyRecordings";
export default {
  components: {
    MyRecordings,
  },
  name: "Recordings",
  data() {
    return {};
  },
};
</script>

<style scoped></style>
