<template>
  <div>
    <v-dialog max-width="400" v-model="dialog">
      <v-card>
        <v-card-title class="text-h6 lighten-2">
          Download recording
          <v-spacer></v-spacer>
          <v-icon @click="closeDialog" size="18" class="white--black">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-6 text-center"
          >Are you ready to start downloading
          <b>{{ recording_item.title }}</b> ?
        </v-card-text>
        <v-card-actions class="pb-10 pt-5">
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDialog"
            class="grey--text capitalize px-10"
            rounded
            text
          >
            Close
          </v-btn>
          <v-btn
            @click="downloadVideo()"
            class="capitalize px-10"
            color="primary"
            rounded
            :loading="loading"
          >
            Proceed to download
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-show="downloading">
      <download-progress
        ref="download_video"
        :recordDetails="recording_item"
      ></download-progress>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DownloadProgress from "../DownloadProgress.vue";
export default {
  name: "DownloadRecordingDialog",
  components: {
    DownloadProgress,
  },
  props: {
    type: String,
    default: null,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      recording_item: {},
      downloading: false,
    };
  },
  methods: {
    ...mapActions("recordings", ["archive_recording"]),

    // this.$emit("load-recordings");
    async downloadVideo() {
      this.loading = true;
      const video_settings = this.recording_item.video_settings;
      if (
        video_settings == null ||
        video_settings["allow_download"] !== "yes"
      ) {
        this.$response.staticError(
          "Video download is not enabled, please enable and try again"
        );
      } else {
        this.downloading = true;
        await this.$refs.download_video.processDownload();
        this.loading = false;
        this.closeDialog();
      }
    },
    openDialog(record) {
      this.recording_item = Object.assign({}, record);
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>
